import { navigateTo } from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
};

const currentYear = "2024";
const dateRange = "October 9th - October 13th";

class RegisterForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // address: "",
      arrivalTime: "",
      attendeesAdult: 1,
      attendeesChildren: 0,
      attendeesFree: 0,
      city: "",
      concerns: "",
      contactAtCamp: "",
      contactAtCampPhone: "",
      contactAtCampRelation: "",
      contactOffCamp: "",
      contactOffCampPhone: "",
      contactOffCampRelation: "",
      email: "",
      message: "",
      name: "",
      namesAdults: "",
      namesChildren: "",
      // paymentCheck: false,
      // paymentInPerson: false,
      // paymentOnline: false,
      payPal: false,
      paymentType: "paymentOnline",
      phone: "",
      price: "",
      pricePerAdult: 140.00,
      pricePerChild: 120.00,
      showModal: false,
      state: "",
      street: "",
      // transactionID: "",
      zip: ""
    };
  }

  render() {
    return (
      <form
        name={`registration_${currentYear}`}
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action="/camp/register/thankyou"
        className='align--left'
      >
        <div className="grid">
          <div>
            <input type="hidden" name="form-name" value={`registration_${currentYear}`} />
            <p className="hidden">
              <label>Don’t fill this out if you&apos;re human: <input name="bot-field" /></label>
            </p>
          </div>

          <p><strong>Camp will be held from {dateRange}, {currentYear}</strong></p>

          <span>
            <h2>1. Contact Information</h2>
            <hr />
          </span>

          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='name'>Name (First and Last)</label>
            <input className='input input--blue' type='text' name='name' placeholder='First Last' value={this.state.name} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--right">
            <label className='input--label' htmlFor='email'>Email</label>
            <input className='input input--blue' type='email' name='email' placeholder='Email' value={this.state.email} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='phone'>Phone Number</label>
            <input className='input input--blue' type='tel' name='phone' placeholder='000-000-0000' value={this.state.phone} onChange={this.handleInputChange} required={true} />
          </div>

          <span>
            <h2>2. Address</h2>
            <hr />
          </span>

          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='street'>Street</label>
            <input className='input input--blue' type='text' name='street' placeholder='Street' value={this.state.street} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--3">
            <label className='input--label' htmlFor='city'>City</label>
            <input className='input input--blue' type='text' name='city' placeholder='City' value={this.state.city} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--3 form-padding--right">
            <label className='input--label' htmlFor='state'>State</label>
            <input className='input input--blue' type='text' name='state' placeholder='--' value={this.state.state} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--3 form-padding--left">
            <label className='input--label' htmlFor='zip'>Zip Code</label>
            <input className='input input--blue' type='text' name='zip' placeholder='00000' value={this.state.zip} onChange={this.handleInputChange} required={true} />
          </div>

          <span>
            <h2>3. Campers</h2>
            <hr />
            <p>Please Enter the Number of Adults and Children that will be staying.</p>
            <ul>
              <li>Children up to 5 years old are free.</li>
              <li>Children 6-12 are ${this.state.pricePerChild}.00</li>
              <li>Adults and children 12 and above are ${this.state.pricePerAdult}.00</li>
            </ul>
          </span>

          <div className="grid--4 form-padding--left">
            <label className='input--label' htmlFor='attendeesAdult'>Number of Adults (${this.state.pricePerAdult}/per)</label>
            <input className='input input--blue' type='number' name='attendeesAdult' placeholder='1' default='1' min='0' value={this.state.attendeesAdult} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--4">
            <label className='input--label' htmlFor='attendeesFree'>Number of Children (Ages 1-5, free)</label>
            <input className='input input--blue' type='number' name='attendeesFree' placeholder='0' default='0' min='0' value={this.state.attendeesFree} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--4 form-padding--right">
            <label className='input--label' htmlFor='attendeesChildren'>Number of Children (Ages 6-12, ${this.state.pricePerChild}/per)</label>
            <input className='input input--blue' type='number' name='attendeesChildren' placeholder='0' default='0' min='0' value={this.state.attendeesChildren} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--12 form-padding">
            <p>Please enter the names of the adults who will be staying, followed by age.</p>
            <label className='input--label' htmlFor='namesAdults'>Names (Adults)</label>
            <textarea rows="3" className='input--blue' name='namesAdults' placeholder='First Last, Age' value={this.state.namesAdults} onChange={this.handleInputChange} required={true} />
          </div>

          { this.state.attendeesFree > 0 || this.state.attendeesChildren > 0 ?
            <div className="grid--12 form-padding">
              <p>Please enter the names of the children who will be staying, followed by age.</p>
              <label className='input--label' htmlFor='namesChildren'>Names (Children)</label>
              <textarea rows="3" className='input--blue' name='namesChildren' placeholder='First Last, Age' value={this.state.namesChildren} onChange={this.handleInputChange} required={true} />
            </div> :
            <div className="grid--12 form-padding hidden">
              <p>Please enter the names of the children who will be staying, followed by age.</p>
              <label className='input--label' htmlFor='namesChildren'>Names (Children)</label>
              <textarea rows="3" className='input--blue' name='namesChildren' placeholder='First Last, Age' value={this.state.namesChildren} />
            </div>
          }

          <span>
            <h2>4. Emergency Contacts</h2>
            <hr />
          </span>

          <h3>Contact (Who is also attending camp)</h3>
          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='contactAtCamp'>Name of Contact (At Camp)</label>
            <input className='input input--blue' type='text' name='contactAtCamp' placeholder='First Last' value={this.state.contactAtCamp} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--right">
            <label className='input--label' htmlFor='contactAtCampPhone'>Phone Number of Contact (At Camp)</label>
            <input className='input input--blue' type='tel' name='contactAtCampPhone' placeholder='000-000-0000' value={this.state.contactAtCampPhone} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='contactAtCampRelation'>Relation of Contact (At Camp)</label>
            <input className='input input--blue' type='text' name='contactAtCampRelation' placeholder='Relation' value={this.state.contactAtCampRelation} onChange={this.handleInputChange} required={true} />
          </div>

          <br />

          <h3>Contact (Who is not attending camp)</h3>
          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='contactOffCamp'>Name of Contact (Offsite)</label>
            <input className='input input--blue' type='text' name='contactOffCamp' placeholder='First Last' value={this.state.contactOffCamp} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--right">
            <label className='input--label' htmlFor='contactOffCamp'>Phone Number of Contact (Offsite)</label>
            <input className='input input--blue' type='tel' name='contactOffCampPhone' placeholder='000-000-0000' value={this.state.contactOffCampPhone} onChange={this.handleInputChange} required={true} />
          </div>

          <div className="grid--6 form-padding--left">
            <label className='input--label' htmlFor='contactOffCampRelation'>Relation of Contact (Offsite)</label>
            <input className='input input--blue' type='text' name='contactOffCampRelation' placeholder='Relation' value={this.state.contactOffCampRelation} onChange={this.handleInputChange} required={true} />
          </div>

          <span>
            <h2>5. Allergies and Special Considerations</h2>
            <hr />
          </span>

          <div className="grid--12 form-padding">
            <label
              className='input--label'
              htmlFor='concerns'
            >
              Do you have any known food allergies or other concerns we need to be aware of?
            </label>
            <textarea rows="5" className='input--blue' name='concerns' placeholder='Special Concerns' value={this.state.concerns} onChange={this.handleInputChange} />
          </div>

          <span>
            <h2>6. Arrival Time</h2>
            <hr />
          </span>

          <div className="grid--12 form-padding">
            <div className="grid--8 form-padding--left">
              <label className='input--label' htmlFor='arrivalTime'>On which day and at what time do you expect to arrive?</label>
              <input className='input input--blue' type='text' name='arrivalTime' placeholder='ie Friday, 2PM' value={this.state.arrivalTime} onChange={this.handleInputChange} required={true} />
            </div>
          </div>

          <div>
            <p>Please note that each person attending will be required to sign a virus waiver (regarding COVID-19) at check-in (in-person). At this point, masks or face coverings will not be required. It is up to your own personal discretion if you choose to wear masks. If you have questions or concerns, please email <a className="link link--blue" href="mailto:charitygracebiblechurch@gmail.com?Subject=ATTN_COVID-19_Concerns" target="_top">charitygracebiblechurch@gmail.com</a>.
            </p>
          </div>

          <span>
            <h2>7. Payment</h2>
            <hr />
          </span>

          <div className="grid--12">
            <h2>Total Amount: {this.calculatePrice()}</h2>
            <p>(Price is calculated based on the number of campers and their age ranges. Assumes a 5 day stay.)</p>
          </div>

          <div>
            <label><h3>Payment Options:</h3></label>
            <span className="flex align-items--center">
              <input
                type="radio"
                name="paymentType"
                id="paymentInPerson"
                style={{ margin: "0" }}
                onChange={this.handleInputChange}
                value="paymentInPerson"
              />
              <label
                style={{ paddingLeft: "0.5rem" }}
                htmlFor="paymentInPerson"
              >
              In Person
              </label>
            </span>

            <span className="flex align-items--center">
              <input
                type="radio"
                name="paymentType"
                id="paymentCheck"
                style={{ margin: "0" }}
                onChange={this.handleInputChange}
                value="paymentCheck"
              />
              <label
                style={{ paddingLeft: "0.5rem" }}
                htmlFor="paymentCheck"
              >
              Check Via Mail
              </label>
            </span>

            <span className="flex align-items--center">
              <input
                defaultChecked={true}
                type="radio"
                name="paymentType"
                id="paymentOnline"
                style={{ margin: "0" }}
                onChange={this.handleInputChange}
                value="paymentOnline"
              />
              <label
                style={{ paddingLeft: "0.5rem" }}
                htmlFor="paymentOnline"
              >
              PayPal
              </label>
            </span>

            <span className="flex align-items--center">
              <input
                type="radio"
                name="paymentType"
                id="paymentPreviouslyPaid"
                style={{ margin: "0" }}
                onChange={this.handleInputChange}
                value="paymentPreviouslyPaid"
              />
              <label
                style={{ paddingLeft: "0.5rem" }}
                htmlFor="paymentPreviouslyPaid"
              >
              Already Paid
              </label>
            </span>

            <span className="flex align-items--center">
              <input
                  type="radio"
                  name="paymentType"
                  id="paymentContactAboutDayRates"
                  style={{ margin: "0" }}
                  onChange={this.handleInputChange}
                  value="paymentContactAboutDayRates"
              />
              <label
                  style={{ paddingLeft: "0.5rem" }}
                  htmlFor="paymentContactAboutDayRates"
              >
              Request contact about day rates
              </label>
            </span>
          </div>

          {this.state.paymentType === "paymentInPerson" && <p>In Person payment is selected. You will be asked to pay when you arrive at camp.</p>}

          {this.state.paymentType === "paymentCheck" && <p>Check Via Mail is selected. The check may be made out to Mary Monroe for {this.calculatePrice()}. Please contact Mary at <a className="link link--blue" href="mailto:charitygracebiblechurch@gmail.com?Subject=ATTN_Mary_Payments" target="_top">charitygracebiblechurch@gmail.com</a> for mailing address.</p>}

          {this.state.paymentType === "paymentOnline" &&
          <span>
            <p>Online Payment via PayPal is selected. Please send <strong>{this.calculatePrice()}</strong> with PayPal before submitting your Registration Form. Please choose friends and family when paying via PayPal.</p>
            <p style={{ padding: "1rem", backgroundColor: "#FF8C63" }}><strong>The link below will open a new tab. Return to this tab to finish submitting your Registration Form when payment is complete.</strong></p>
            <a
              className="button button--blue"
              href={`https://paypal.me/charitygracebible/${this.calculatePriceLink()}`}
              target="_blank"
              onClick={() => this.setState({ payPal: true })}
            >
              Go To PayPal
            </a>
          </span>
          }

          {this.state.paymentType === "paymentPreviouslyPaid" && <p>If you previously paid (this year) and were notified that your registration form was not received, please finish by clicking &quot;Submit Registration&quot;. We apologize for the inconvenience.</p>}

          {this.state.paymentType === "paymentContactAboutDayRates" &&
              <>
            <p style={{ marginBottom: "0" }}>If you do not plan to stay the entire camp (Thursday-Sunday), but instead will be joining us for only part of the time, please select this option.</p>

            <p style={{ padding: "1rem", backgroundColor: "#FF8C63", marginBottom: "0" }}>We will contact you at your provided phone number
            (<strong>{this.state.phone}</strong>)
            and/or your email address
            (<strong>{this.state.email}</strong>)
            to ask you about what days you plan to stay and confirm what the price will be.</p>

            <p style={{ marginTop: "0" }}>
              If you have questions about day rates, please contact us at<a className="link link--blue" href="mailto:charitygracebiblechurch@gmail.com?Subject=ATTN_Mary_DayRates" target="_top">charitygracebiblechurch@gmail.com</a>.
            </p>
          </>
          }

          <div>
            <hr />
            <button
                id='submit'
                className={`button button--green ${this.state.paymentType === "paymentOnline" && this.state.payPal !== true && "disabled"}`}
                type='submit'
                value="Send"
                style={{ width: "100%", padding: "1rem 2rem", fontSize: "1.25rem" }}>
              Submit Registration
            </button>
          </div>
        </div>

      </form>
    );
  }

  calculatePrice() {
    const valueAdult = this.state.attendeesAdult * this.state.pricePerAdult;
    const valueChildren = this.state.attendeesChildren * this.state.pricePerChild;
    let value = valueAdult + valueChildren;
    value = new Intl.NumberFormat("en-EN",
      {
        currency: "USD",
        style: "currency"
      }).format(value);
    return value;
  }

  calculatePriceLink() {
    const valueAdult = this.state.attendeesAdult * this.state.pricePerAdult;
    const valueChildren = this.state.attendeesChildren * this.state.pricePerChild;
    const value = valueAdult + valueChildren;
    return value;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    const form = event.target;
    fetch("/register/", {
      body: encode({ "form-name": "register", ...this.state }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST"
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
    event.preventDefault();
  }

}

RegisterForm.propTypes = {
  data: PropTypes.object
};

RegisterForm.defaultProps = {
  data: {}
};

export default RegisterForm;
